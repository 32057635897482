@import "src/styles";
@include themes;

$hide-bg-breakdown: 790px;
$change-ratio: 1000px;

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: $white;
  font-family: $charsmith-content;
  position: relative;
  // font-family: Mali;
  // font-family: "Autour One";
  // font-family: DynaPuff;
  // font-family: Oregano;
  // font-family: Original Surfer;
  // font-family: Sour Gummy;

  a {
    text-decoration: none;
  }
}

.content {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 70px);
  width: 100%;
  position: relative;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
  }
}

.scroll-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--background);
  box-sizing: border-box;
  z-index: 1;
  flex: 1 0;

  margin: 40px;
  overflow: auto;
  border-radius: 40px;


  p {
    text-align: center;
  }

  @media screen and (max-width: $hide-bg-breakdown) {
    left: 40px;
  }
}

.scroll {
  height: 100%;
  padding: 20px 40px 40px;
}

.mascot {
  height: calc(100vh - 120px);
  animation: fadein 1s linear;
}

.title {
  font-family: "Carter One";
  text-align: center;
}

.sub-title {
  text-decoration: underline;
  text-align: center;
  margin: 10px;
}

.bg-container.bg-container {
  position: sticky;
  top: 100px;
  left: 25px;
  flex: 1 0;
  @media screen and (max-width: $change-ratio) {
    flex: 1.5 0;
  }

  @media screen and (max-width: $hide-bg-breakdown) {
    display: none;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.vtt-button-link.vtt-button-link {
  @media screen and (max-width: 415px) {
    width: 100%;
    margin: 5px;
    .vtt-logo {
      display: none;
    }
    .vtt-button {
      height: unset;
      width: 100%;
    }
  }
}
.vtt-button.vtt-button {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  height: 100px;
  width: 260px;
  font-size: $font-size-large-5;
  padding: 20px;
  cursor: alias;

  .label.label {
    flex-shrink: 0;
    text-decoration: none;
    text-align: center;
    flex: 1;
  }
}

.wildsea.wildsea.wildsea {
  @include font-title;
  background-color: $green;
}

.root.root.root {
  font-family: Luminari, sans-serif;
  background-color: $orange;
}

.pico.pico.pico {
  font-family: "Babydoll", sans-serif;
  background-color: $dark-blue;
}

.vtt-logo.vtt-logo {
  width: 60px;
  height: 60px;
}

.root-bg.root-bg {
  --bg-0: url("./rbg-0.jpg");
  --bg-1: url("./rbg-1.jpg");
  --bg-2: url("./rbg-3.jpg");
  --bg-3: url("./rbg-6.jpg");
  --bg-4: url("./rbg-5.jpg");
  --bg-5: url("./rbg-3.jpg");
}

.pico-bg {
  --bg-0: url("./pico-splash-2.png");
  --bg-1: url("./pico-splash-4.png");
  --bg-2: url("./pico-splash-6.png");
  --bg-3: url("./pico-splash.png");
  --bg-4: url("./pico-splash-5.png");
  --bg-5: url("./pico-splash-3.png");
  // --bg-0: url("./picobg2.jpg");
  // --bg-1: url("./picobg2.jpg");
  // --bg-2: url("./picobg2.jpg");
  // --bg-3: url("./picobg2.jpg");
  // --bg-4: url("./picobg2.jpg");
  // --bg-5: url("./picobg2.jpg");
}

.force-load {
  opacity: 0;
}

.wildsea-header {
  font-family: $font-family-title;
  margin-top: 2px;
}

.pico-header {
  font-family: "Babydoll", sans-serif;
  margin-top: 2px;
}

.small-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -15px 20px -5px;
  > * {
    max-width: 200px;
    border-radius: 100%;
    border: 5px solid var(--secondary);
    overflow: hidden;
  }
}

.background.background {
  position: fixed;
  inset: 0;
  background-color: $white;
  animation: huerotate 40s linear infinite;
  z-index: 0;
}

@keyframes huerotate {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}

.placeholder.placeholder {
  pointer-events: none;
  cursor: default;
}