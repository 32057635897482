@import './colors';

$primary-themes: (light: ( // logo: url("../images/brand/logo_vertical.svg"),
        background: $white,
        inverse: $black,
        light-gray: $light-gray,
        light-gray-2: $light-gray-2,
        font: $black,
        border: $black,
        secondary: var(--secondary-color)),
    dark: ( // logo: url("../images/brand/logo_vertical--invert.svg"),
        background: darken($black, 5%),
        inverse: $white,
        light-gray: $dark-gray,
        light-gray-2: $dark-gray-2,
        font: $white,
        border: $white,
        secondary: var(--secondary-color),
    ),
);

// ON UPDATE, UPDATE the export statement also!
$secondary-themes: (red: (secondary-color: $red,
        secondary-light: lighten($red, 15%),
        contrast: $light-blue,
    ),
    orange: (secondary-color: $orange,
        secondary-light: lighten($orange, 15%),
        contrast: $light-blue,
    ),
    yellow: (secondary-color: $yellow,
        secondary-light: lighten($yellow, 10%),
        contrast: $light-blue,
    ),
    green: (secondary-color: $green,
        secondary-light: lighten($green, 15%),
        contrast: lighten($orange, 5%),
    ),
    light-blue: (secondary-color: darken($light-blue, 10%),
        secondary-light: lighten($light-blue, 5%),
        contrast: lighten($orange, 5%),
    ),
    dark-blue: (secondary-color: $dark-blue,
        secondary-light: lighten($dark-blue, 15%),
        contrast: lighten($orange, 10%),
    ),
    purple: (secondary-color: $purple,
        secondary-light: lighten($purple, 15%),
        contrast: lighten($orange, 10%),
    ),
    dark-pink: (secondary-color: $dark-pink,
        secondary-light: lighten($dark-pink, 15%),
        contrast: $light-blue,
    ),
    light-pink: (secondary-color: $light-pink,
        secondary-light: $dark-pink,
        contrast: $light-blue,
    ),
);

:export {
    red: $red;
    orange: $orange;
    yellow: $yellow;
    green: $green;
    purple: $purple;
    dark-pink: $dark-pink;
    light-pink: $light-pink;
    dark-blue: $dark-blue;
    light-blue: $light-blue;
    light-gray: $light-gray-2;
}

@mixin themes {

    @each $theme,
    $map in $primary-themes {
        .primary-theme-#{'' + $theme} {

            @each $key,
            $value in $map {
                --#{$key}: #{$value};
            }
        }
    }

    @each $theme,
    $map in $secondary-themes {
        .secondary-theme-#{'' + $theme} {

            @each $key,
            $value in $map {
                --#{$key}: #{$value};
            }
        }
    }
}