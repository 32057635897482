@font-face {
  font-family: "Luminari";
  font-style: normal;
  font-weight: normal;
  src:
    local("Luminari"),
    url("/public/Luminari-Regular.woff") format("woff");
}
@font-face {
  font-family: "Babydoll";
  font-style: normal;
  font-weight: normal;
  src:
    local("Babydoll"),
    url("/public/Baby Doll.ttf") format("opentype");
}
