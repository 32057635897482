@import "~/src/styles/";

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-container {
  width: 330px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.icon-container {
  height: 24px;
}

.text-container {
  font-size: $font-size-large;
}

.wide {
  width: fit-content;
}
