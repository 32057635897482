@import "src/styles";
@include themes;

.container {
    color: var(--inverse);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: min(400px, 80vw);
    min-height: 200px;
}

.description {
    flex: 1;
    padding: 10px;
    text-align: center;
}

.buttons {
    display: flex;
    align-self: stretch;
    gap: 10px;

    >* {
        flex: 1;
    }

    .continue {
        background-color: $red;
        color: $white;
    }
}