@import "src/styles";
@include themes;

.link {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 2px;
    color: unset;

    &.fit-width {
        margin-right: auto;
    }

    &:visited {
        color: $light-gray-2;
    }

    svg {
        height: 24px;
        width: 24px;
        margin-bottom: -2px
    }
}

.inline-email {
    display: inline-flex;
    vertical-align: bottom;
    font-size: unset;
}

.inline-email-icon.inline-email-icon.inline-email-icon {
    width: 15px;
    height: 15px;
}