@import "~/src/styles";
@include themes;

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    background-color: var(--secondary);
    color: $white;
    gap: 10px;
    z-index: 1;
}

.title-container {
    display: flex;
    align-items: flex-end;

    &.hide-overflow {
        overflow: hidden;
    }
}

.title {
    @include font-title;
    &.hide-overflow {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    font-size: $font-size-header-title;
    margin-bottom: -10px;
    margin-top: -2px;
    user-select: none;
    margin-right: 9px;
}

.title-icon {
    >svg {
        width: $font-size-header-title;
        height: $font-size-header-title;
        color: $white;
    }
}

.subtitle {
    @include font-subtitle;
    font-size: $font-size-large-2;
    margin-bottom: 4.5px;
    user-select: none;
}