@import "~/src/styles";

.modal-content {
    width: 80vw;
    max-width: 800px;
    max-height: 60vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.version-container {
    display: flex;
    gap: 12px;
    margin-bottom: 6px;
    align-items: flex-end;
}

.version {
    font-size: $font-size-large-4;
    font-weight: $font-weight-extra-bold;
    font-family: $font-family-title;
    margin-bottom: -7px;
}

.version-link.version-link.version-link {
    display: inline-flex;
    vertical-align: bottom;
    font-size: inherit;
    color: $light-blue;
    gap: 3px;

    svg {
        width: 15px;
    }
}


.modal-header {
    justify-content: flex-start;
}

.spacer {
    flex: 1;
}

.jump-button {
    justify-self: flex-end;
    color: $white;

    &.contrast {
        background-color: var(--contrast);
    }

    @media screen and (max-width: 570px) {
        visibility: hidden;
    }
}

.bell-container {
    position: relative;
}

@keyframes jingle {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(-12deg);
    }

    20% {
        transform: rotate(10deg);
    }

    30% {
        transform: rotate(-10deg);
    }

    40% {
        transform: rotate(6deg);
    }

    50% {
        transform: rotate(-6deg);
    }

    60% {
        transform: rotate(4deg);
    }

    70% {
        transform: rotate(-4deg);
    }

    80% {
        transform: rotate(2deg);
    }

    90% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.bell {
    animation: jingle 2s ease-in-out infinite;
    /* Slower, longer duration */
    transform-origin: top center;
    animation-timing-function: steps(10, end);
    /* Adds an intermittent feel */
    vertical-align: middle;
    width: 16px;
    margin-right: 5px;

    &.angry {
        animation-duration: 1s;
    }
}