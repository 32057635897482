@import "src/styles";
@include themes;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url("./base.png");
  background-size: cover;
  width: 100%;
  height: 100%;

  &.round {
    border-radius: 100%;
    overflow: hidden;
  }

  &.rounded {
    border-radius: $border-radius;
    overflow: hidden;
  }

  --bg-0: url("./bg.png");
  --bg-1: url("./bg4.png");
  --bg-2: url("./bg9.png");
  --bg-3: url("./bg5.png");
  --bg-5: url("./bg6.png");
  --bg-4: url("./bg7.png");
}

.image.image {
  background-image: url("./base.png");
  -webkit-transition: background-image 5s ease-in-out;
  transition: background-image 5s ease-in-out;
  background-position: center;
  background-size: cover;

  &.bg-0 {
    background-image: var(--bg-0);
  }

  &.bg-1 {
    background-image: var(--bg-1);
  }

  &.bg-2 {
    background-image: var(--bg-2);
  }

  &.bg-3 {
    background-image: var(--bg-3);
  }

  &.bg-4 {
    background-image: var(--bg-4);
  }

  &.bg-5 {
    background-image: var(--bg-5);
  }
}

.error-icon {
  color: $gray;
}
