@import "src/styles";
@include themes;

.BLOODLINE {
    color: lighten($red, 30%);
}

.ORIGIN {
    color: lighten($yellow, 20%);
}

.POST {
    color: lighten($light-blue, 20%);
}