$font-family-title: "teko", sans-serif;
$font-family-content: "Merriweather", serif;

$charsmith-title: "Carter One";
$charsmith-content: "Carter One";
// $test: "Love Ya Like A Sister";

//both: DynaPuff, Original+Surfer, Sour+Gummy
//title: Boogaloo, Combo, Jersey, Joti+One, Kalam, Permanent+Marker, Ribeye, Viaoda+Libre,Carter+One, Love+Ya+Like+A+Sister,Oregano
@mixin font-title {
  font-family: $font-family-title;
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

@mixin font-subtitle {
  font-family: $font-family-title;
  font-weight: $font-weight-bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

$font-weight-regular: 400;
$font-weight-bold: 500;
$font-weight-semi-bold: 600;
$font-weight-extra-bold: 800;

$font-size-header-title: 50px;
$font-size-breakpoint-header-title: 35px;
$font-size-large-5: 26px;
$font-size-large-4: 24px;
$font-size-large-3: 22px;
$font-size-large-2: 20px;
$font-size-large: 18px;
$font-size-regular: 16px;
$font-size-small: 14px;
$font-size-small-2: 12px;
