@import "src/styles";
@import "./sectionVars.module.scss";
@include themes;

.container {
    display: flex;
    flex-direction: column;
}


// 47px height       9
// 15px top margin   3
// 5px bottom margin 1

.header {
    height: 24px;
    background-color: var(--secondary-color);
    margin-left: -3px;
    margin-right: -3px;
    margin-bottom: -6px;
    position: relative;
    padding-left: 6px;
    padding-top: 0px;
    padding-bottom: 3px;
    @include font-title;
    font-size: $font-size-large-4;
    text-shadow: $shadow;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;

    -webkit-clip-path: polygon(calc(0% + 6px) 0%, 100% 0, 100% 50%, calc(100% - 15px) 100%, 0 100%, 0 calc(0% + 6px));
    clip-path: polygon(calc(0% + 6px) 0%, 100% 0, 100% 50%, calc(100% - 15px) 100%, 0 100%, 0 calc(0% + 6px));

    display: flex;
    align-items: center;
    gap: 6px;

    .label {
        display: flex;
        gap: 6px;
        align-items: baseline;
        flex: 1;
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
    }

    .sublabel {
        font-size: $font-size-large;
        letter-spacing: 1px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.secondary {
        background-color: var(--secondary-light);
    }

    &.contrast {
        background-color: var(--contrast);
    }

    &.gray {
        background-color: $dark-gray-2;
    }

    &.disabled {
        background-color: $gray;
    }

    &.expandable {
        cursor: pointer;
    }
}

.buffer {
    height: 5px;
    box-sizing: border-box;

    border: 2px solid var(--secondary-color);
    border-bottom: 0px;
    border-radius: 30px 30px 0 0;
    flex-shrink: 0;

    &.borderless,
    &.collapsed {
        border: 0;
    }

    &.secondary {
        border-color: var(--secondary-light);
    }

    &.gray {
        border-color: $dark-gray-2;
    }

    &.contrast {
        border-color: var(--contrast);
    }

    &.disabled {
        border-color: $gray;
    }
}

.content {
    border: 2px solid var(--secondary-color);
    border-top: 0px;
    border-radius: 0 0 6px 6px;
    font-family: $font-family-content;
    padding: $content-padding;

    &.borderless.borderless.borderless {
        border: 0;
    }

    &.secondary {
        border-color: var(--secondary-light);
    }

    &.gray {
        border-color: $dark-gray-2;
    }

    &.contrast {
        border-color: var(--contrast);
    }

    &.disabled {
        border-color: $gray;
    }

    &.no-padding {
        padding: 0;
        // to not run into the header
        padding-top: 6px;
    }

    &.fill {
        height: 100%;
    }

    &.hidden {
        display: none;
    }
}

.loader {
    width: 20px;
    height: 20px;
}

.suffix {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-right: 15px;
    margin-top: 3px;
}

.header-suffix {
    display: flex;
    gap: 5px;
}

.expander {
    width: 30px;
    height: 30px;
    transition: transform .2s linear;

    &.expanded {
        transform: rotate(180deg);
    }
}

.unexpanded.unexpanded.unexpanded {
    height: 400px;
    overflow: auto;
}

.expanded {
    height: unset;
}

.chevron {
    margin-top: 3px;
    margin-left: -3px;
    filter: drop-shadow($shadow);
}

.expansion-logo {
    transform: translateY(2px);
    height: 20px;
    margin-left: 2px;

    >* {
        width: 100%;
        height: 100%;
    }
}

.lock-button {
    color: $white;

    &.locked {
        color: $light-gray-2;
    }
}

.resizable-content {
    border-radius: $border-radius $border-radius 0 0;
}

.resizer {
    border-radius: 0 0 $border-radius $border-radius;
}