@import "~/src/styles";

@include themes;

.huge.huge {
    max-width: 90vw;
    width: 600px;
}

.unrestricted.unrestricted {
    max-width: unset;
}

.tooltip {
    font-family: $font-family-content;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.25);
    border: solid 2px var(--secondary);
    background-color: var(--background);
    color: var(--font);
    box-sizing: border-box;
    max-width: min(500px, 90vw);

    h3 {
        color: var(--secondary);
    }

    u {
        color: var(--secondary-light)
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #cacaca;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 80px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #cacaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 80px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.tail {
    background-color: var(--background);
}