.loader {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg) scaleX(-1);
    }

    to {
        transform: rotate(360deg) scaleX(-1);
    }
}

.loader-with-text {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;

    &.centered {
        justify-content: center;
    }

    &.no-padding {
        padding: 0;
    }
}