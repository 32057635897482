@import "src/styles";
@include themes;

.container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-top: 12px;
    box-sizing: border-box;
}

.button {
    flex: 1;
}