@import "~/src/styles";

.portal {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    pointer-events: none;

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #cacaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 80px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.mask {
    @extend .portal;
    background-color: $black;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
    opacity: 0;

    &.displayed {
        pointer-events: auto;
        opacity: 50%;
    }
}

.portal-component {
    pointer-events: auto;
}