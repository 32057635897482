@import "~/src/styles/";

@include themes;

$background-opacity: 10%;

@mixin button-font {
  font-family: $font-family-title;
  font-size: $font-size-large;
  color: var(--font);
  letter-spacing: 1px;
  line-height: 20px;
}

@mixin button {
  height: 36px;
  display: flex;
  @include button-font;
  justify-content: center;
  align-items: center;
  padding: 6px;
  padding-top: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  transition: background-color 0.3s ease-out;

  &.dark {
    color: $white;
  }

  &.gray {
    color: $light-gray;
  }

  &.square {
    padding: 6px;
    height: 36px;
    width: 36px;
    flex: none;
  }

  &.full-width {
    width: 100%;
  }

  &.enable-hover:hover {
    > .background {
      opacity: 20%;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 40%;

    > .background:not(.transparent-background) {
      opacity: $background-opacity;
    }
  }

  &.presentation {
    cursor: default;
  }

  &.active {
    border: 2px $border-style $light-gray-2;

    > .background {
      opacity: $background-opacity;
    }

    // &.dark {
    //     border-color: $white;
    // };
  }

  &.selected {
    @extend .active;
    cursor: default;
  }

  &.clicked:not(.selected) {
    background-image: linear-gradient(rgb(48 47 48/10%) 0 0);

    &.enable-movement {
      transform: translateY(1px);
    }

    > .background {
      opacity: $background-opacity;
    }
  }

  &.flex {
    flex: 1;
  }

  &.icon {
    @extend .square;

    > svg {
      width: 18px;
      height: 18px;
    }
  }

  &.rotation {
    @extend .icon;

    &:hover:not(:disabled) {
      > svg {
        animation-play-state: running;
      }
    }

    > svg {
      animation: rotation 2s infinite linear;
      animation-play-state: paused;
    }
  }

  &.round {
    @extend .icon;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    > svg {
      transform: scale(1.25);
    }
  }

  &.fit-content {
    padding: 0;
    height: auto;
    width: auto;
  }

  &.colorful {
    background-color: var(--secondary);
    color: $white;
  }

  &.large {
    padding: 18px;
    padding-top: 20px;
    font-size: $font-size-large-4;
    font-weight: $font-weight-bold;

    &.icon {
      padding: 0;

      > svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.button {
  position: relative;
  @include button;
}

.background {
  position: absolute;
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: 100%;
  opacity: $background-opacity;
  background: var(--inverse);
  border: 0;
  left: 0;
  top: 0;

  &.dark {
    background: $white;
  }

  &.fit-content {
    background: none;
  }

  &.transparent-background {
    opacity: 0;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
