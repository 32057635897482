@import "src/styles";
@include themes;

$logo-switch-bp: 1050px;

.container {
    margin-top: 10px;
    background-color: var(--secondary);
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    color: $white;
    z-index: 1;
}

.mascot {
    position: absolute;
    left: -324px;
    top: 0px;
    height: 100%;
    width: 250px;


    @media screen and (max-width: 1440px) {
        left: -165px;
        width: 150px;
    }

    @media screen and (max-width: $logo-switch-bp) {
        display: none;
    }
}

.firefly-logo-big {
    position: absolute;
    right: -300px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;

    @media screen and (max-width: 1440px) {
        right: -145px;
    }

    @media screen and (max-width: $logo-switch-bp) {
        display: none;
    }
}

.inner-container {
    position: relative;
    padding: 20px 0px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.legal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    .sub {
        font-size: $font-size-small;
    }
}

.main {
    font-weight: $font-weight-semi-bold;
}

.links {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.link-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.link {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 2px;
    color: unset;

    &.fit-width {
        margin-right: auto;
    }

    &:visited {
        color: $light-gray-2;
    }

    svg {
        height: 24px;
        width: 24px;
        margin-bottom: -2px
    }
}

.modal {
    width: 600px;
    max-width: 80vw;
}

.small-logos {
    display: none;
    gap: 10px;
    align-items: center;

    @media screen and (max-width: $logo-switch-bp) {
        display: flex;
    }
}