@import "src/styles";
@include themes;

.container {
    display: flex;
    flex-direction: column;
    padding-left: 6px;
    margin-block-start: 0;
    margin-block-end: 0;
    gap: 3px;
    font-size: $font-size-regular;
    line-height: $font-size-large-2;

    &.inherit.inherit {
        font-size: inherit;
    }
}

.inner-container {
    padding-left: 24px;
}

.item-container {
    display: flex;
    align-items: flex-start;
    gap: 0px;
    text-align: left;
    gap: 10px;
}

.dot.dot {
    transform: scale(2);
    margin-top: 2px;
}

.bullet {
    flex: none;
    margin-top: 2px;
    width: 15px;
    height: 15px;
}

.warning {
    color: $yellow;
}

.important {
    color: $blue-text;
}

.link {
    color: $light-blue;

    &:visited {
        color: $dark-blue;
    }
}