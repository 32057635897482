@import "~/src/styles";
@include themes;

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: relative;
    background-color: var(--background);
    transition: opacity 0.2s ease-out;
    opacity: 0;
    max-width: 90vw;

    display: flex;
    flex-direction: column;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &.opened {
        opacity: 1;
        pointer-events: all;
    }
}

.content {
    padding: 12px;
    color: var(--font);
    font-family: $font-family-content;
    border: $border;
    border-top: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.title {
    @media screen and (max-width: 600px) {
        font-size: $font-size-large-2;
    }
}

.close {
    width: 16px;
    height: 16px;
}

.hidden {
    display: none;
}