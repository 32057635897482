$black: #302f30;
$white: #ffffff;
$white-gray: #d3d3d3;
$gray: #9a999a;
$light-gray: lighten($gray, 10%);
$light-gray-2: lighten($gray, 20%);
$dark-gray: darken($gray, 10%);
$dark-gray-2: darken($gray, 20%);
$link: #2676d1;

$red: #ad4b51;
$orange: #c3613d;
$yellow: #db9a2a;
$green: #6b7c53;
$light-blue: #6a98b9;
$dark-blue: #2d5c7b;
$purple: #75578a;
$dark-pink: #b370a4;
$light-pink: #d893c8;

$blue-text: #4393e2;

:export {
    black: $black;
    gray: $gray;
    lightGray2: $light-gray-2;
}