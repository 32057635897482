@import "src/styles";
@include themes;

.default {
    background: var(--secondary);
    cursor: nwse-resize;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;

    &.secondary-light {
        background: var(--secondary-light);
    }

    &.contrast {
        background: var(--contrast);
    }

    &:hover {
        .line {
            top: 1px;
            background-color: var(--secondary) // box-shadow: -1px 1px 15px $light-gray;
        }
    }
}

.row {
    height: 7px;
    width: 100%;
    cursor: row-resize;
}

.column {
    width: 2px;
    height: 100%;
    cursor: column-resize;
}

.indicator {
    position: absolute;
    top: -8px;
    width: 33%;
    max-width: 200px;
    height: 8px;

    .line {
        transition: all .1s linear;
        position: absolute;
        top: 3px;
        left: 0px;
        right: 0px;
        height: 3px;
        background-color: var(--light-gray);
    }
}

.column-indicator {
    transform: rotate(90deg);
    gap: 2px;
}