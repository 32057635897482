@import "src/styles";
@include themes;

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    vertical-align: middle;

    &.left {
        object-position: 0 50%;
    }

    &.right {
        object-position: 100% 50%;
    }

    &.top {
        object-position: 50% 0;
    }

    &.top-left {
        object-position: 0 0;
    }

    &.top-right {
        object-position: 100% 0;
    }

    &.bottom {
        object-position: 50% 100%;
    }

    &.bottom-left {
        object-position: 0 100%;
    }

    &.bottom-right {
        object-position: 100% 100%;
    }

    &.portrait {
        object-position: 50% 25%;
    }

    &.round {
        border-radius: 100%;
    }

    &.rounded {
        border-radius: $border-radius;
    }

    &.contain {
        object-fit: contain;
    }
}