@import "~/src/styles";
@include themes;

$breakpoint-two: 740px;

body:has(.primary-theme-light) {
  background: $white;
}

body:has(.primary-theme-dark) {
  background: darken($black, 5%);
}

.app {
  display: flex;
  flex-direction: column;
  font-family: $font-family-content;
  font-size: $font-size-regular;
  background-color: var(--background);
  color: var(--font);
  max-height: 100vh;
  height: 100vh;

  input,
  textarea {
    font-family: $font-family-content;
    font-size: $font-size-small;
  }
}

.scrollable {
  flex: 1;
  overflow: auto;
  position: relative;
}

.screenshot {
  overflow: visible;
}

.content {
  min-height: 100%;
}

.tooltip-provider {
  height: 100%;
}

.nav-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  padding: 5px 0;
  width: 100%;
  button {
    font-family: $charsmith-content;
    font-size: $font-size-regular;
  }

  .divider {
    height: 35px;
    width: 2px;
    background-color: $white;
  }

  @media screen and (max-width: $breakpoint-two) {
    display: none;
  }
}

.out-link {
  text-decoration: none;
  button {
    cursor: alias !important;
  }
}

.header.header.header {
  font-family: $charsmith-title;
  font-size: $font-size-large-4;
  margin: 5px 0 6px 5px;
}

.root {
  font-family: Luminari, sans-serif;
  background-color: $orange;
}

.block {
  display: flex;
  gap: 10px;
  align-items: center;

  .buttons {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  label {
    font-size: $font-size-large-2;
  }
}

.compendium-extra-button {
  @media screen and (max-width: 540px) {
    display: none;
  }
}
