@import "src/styles";
@include themes;

.description {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.icon {
    >svg {
        width: 100px;
        height: 100px;

    }

    color: $green;

    &.dirty {
        color: $yellow;
    }
}

.continue-button {
    &:not(.dirty) {
        background-color: $green;
    }
}